<template>
  <div class='default-table'>
    <br>
    <div class="switch_items">
      <div>
        <!--        公式计算:-->
        <!--        <el-switch v-model="formatPoint" active-color="#13ce66" inactive-color="#ff4949"-->
        <!--                   @change="changeLocalStorage('formatPoint')">-->
        <!--        </el-switch>-->
      </div>
      <div>
        <!--        表格编辑:-->
<!--                <el-switch v-model="tableEdit" active-color="#13ce66" inactive-color="#ff4949"-->
<!--                           @change="changeLocalStorage('tableEdit')">-->
<!--                </el-switch>-->
      </div>
      <div>
        <i style="cursor: pointer" title="刷新表格" class="el-icon-refresh"
           @click="()=>{ this.$emit('callbackData', true)}"></i>
      </div>
    </div>
    <br>
    <br>
    <el-table
        id="kolAnalysisTable"
        ref='myTable'
        v-loading='isLoading'
        :cell-class-name="cellClassName"
        :data='tableCol'
        :header-cell-class-name="headerClassName"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        border
        default-expand-all
        header-align='left'
        row-key='id'
        style='width: 100%;'
        width='150'
        :header-cell-style="columnbackgroundStyle"
        @row-contextmenu="rowContextmenu"
    >
      <el-table-column :label='`${projectDate.year}年`' width='180'>
        <template slot-scope='{ row }'>
          <template>
            <a :title="hoverTitle(row)"> {{ row.name }}</a>
<!--            <i v-show='row.children'-->
<!--               class='el-icon-plus' style='cursor: pointer' @click='dialogShow(row)'></i>-->
          </template>
        </template>
      </el-table-column>
      <el-table-column v-for='(col, i) in newDataList' :key="i + 'month'" align='center'>
        <template slot='header'>
          {{ col?.month }} 月
          <i class="el-icon-refresh" style="font-size: 12px;margin-left: 10px;color: #ff3176; cursor: pointer"
             v-if="userPermissions.indexOf('kol-analysis-item-update') != -1" @click="refreshBtn(col)"></i>
        </template>
        <!-- 有子项的合计-->
        <editable-cell
            v-if='row.children'
            slot-scope='{ row }'
            v-model='col[row.value].total'
            :click-edit="false"
            :is-input-number='true'
            :show-input='row.editMode'
            @change='changeVal(col, row)'
        >
          <template slot='content'>
            <span v-if="['fans_total','incr_fans_total'].includes(row.value)"> {{
                col[row.value].total || '-'
              }}
            </span>
            <span v-else>{{ moneyFormat(col[row.value].total) || '-' }}</span>
          </template>

        </editable-cell>
        <!--        没有子项-->
        <editable-cell
            v-else-if='!col[row.value]?.items'
            slot-scope='{ row }'
            v-model='col[row.value]'
            :click-edit="false"
            :is-input-number='true'
            :show-input='row.editMode'
            @change='changeVal(col, row)'>
            <span slot='content'>
              <template v-if="row.value === 'coop_duration'">
                {{ 0 >= col[row.value] ? '-' : col[row.value] }} 个月
              </template>
              <template v-else-if="row.value==='kol_divide_rate'">
              {{ col?.[row.value] || headData.kol_divide_rate || '-' }}%
              </template>
              <template v-else>
                {{ moneyFormat(col[row.value]) }}
              </template>
            </span>
        </editable-cell>
        <!--        子项-->
        <span v-else slot-scope='{ row }'>
            <editable-cell
                v-for='(item, i) in col[row.value].items'
                v-show="item.name != '' && item.name === row.name"
                :key='i'
                v-model='item.val'
                :click-edit="false"
                :is-input-number='true'
                :placeholder='item.placeholder'
                :show-input='row.editMode'
                @change='changeVal(col, row,)'
            >
              <template slot='content'><span v-if="['fans_total','incr_fans_total'].includes(row.value)">
                               {{ item.name === row.name ? item.val : '' }} </span>
                <span v-else> {{ item.name === row.name ? moneyFormat(item.val) : '' }} </span>
              </template>
            </editable-cell>
        </span>
      </el-table-column>
      <el-table-column label='合计' width='180' align="right">
          <span slot-scope='{ row }'>
           {{ hasAmount.includes(row.value) ? '-' : moneyFormat(calcRow(row)) }}
          </span>
      </el-table-column>
    </el-table>
    <br>
    <el-button type="primary" @click="exportExcel" v-if="userPermissions.indexOf('kol-analysis-item-export') !== -1"
               :loading=downloadLoading plain icon="el-icon-download" style="float: left">导出表格
    </el-button>
    <br>
    <br>
    <line-chart lineType="kol" :dataList="newDataList"/>
    <br>
    <tip-list :tipsList="tipsList" :tipsConfig="tipsConfig"/>
    <br>
    <log-list ref="userLog" :logIds="logIds" v-if="userPermissions.indexOf('kol-analysis-item-log') !== -1"/>
    <br>
    <el-dialog :visible.sync='dialogStore' center title='新增项目平台' width='550px'>
      <el-form label-position='right' label-width='110px'>
        <el-form-item label='项目名称' prop='proj_name'>
          <el-input v-model='addColTitle' placeholder='新增项目平台'/>
          <span style="font-size: 12px;color: red">{{ dialgText }}</span>
        </el-form-item>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogStore = false'>取 消</el-button>
        <el-button :disabled="addColTitle === ''" type='primary' @click='addNewField'>确 定</el-button>
      </span>
    </el-dialog>
    <edit-btn :menu-data="menuData" @change="changeCheck"/>
    <!--    <el-dialog title="同步" :before-close="cancelBtn" @close="cancelBtn" :visible.sync="dialogSync" width="50%" center>-->
<!--          <el-button @click="refreshBtn">同步</el-button>-->
    <!--&lt;!&ndash;      <sync-table ref="sync" @cancel="cancelBtn" :projectDate="projectDate" :artistId="artistId" :sysDate="sysDate"&ndash;&gt;-->
    <!--&lt;!&ndash;                  v-if="dialogSync" :colData="colData"></sync-table>&ndash;&gt;-->
    <!--    </el-dialog>-->
  </div>
</template>

<script>
import {editTable} from '@/components/mixins/editable'
import TipList from '@/components/TipsList.vue'
import * as XLSX from 'xlsx-js-style'
import EditBtn from '@/pages/kolModel/components/EditBtn.vue'
import LogList from '@/pages/kolModel/components/LogList.vue'
import {mapGetters} from 'vuex'
import LineChart from '@/pages/kolModel/components/LineChart.vue'
// import syncTable from '@/pages/kolModel/components/SyncTable.vue'


export default {
  name: 'DataTable',
  mixins: [editTable],
  components: { LogList, TipList, EditBtn, LineChart },
  data() {
    return {
      isLoading: true,
      downloadLoading: false,
      hasAmount: [
        // 'income_incr_rate',
        'coop_duration', 'fixed_total', 'fans_total', 'fans_incr_rate', 'fans_price', 'kol_divide_rate'
      ],
      dataList: [],
      colData: [
        {
          id: 1,
          value: 'coop_duration',
          name: '孵化时长',
          width: 100,
          component: 'el-input'
        },
        {
          id: 2,
          value: 'income_total',
          name: '收入合计',
          width: 100,
          component: 'el-input'
        },
        //   保量收入 商务营收
        {
          id: 3,
          value: 'stage_total',
          name: '保量收入',
          width: 100,
          // component: 'el-input',
          children: []
        },
        {
          id: 4,
          value: 'biz_total',
          name: '商务营收',
          width: 100,
          // component: 'el-input',
          children: []
        },
        // {
        //   id: 3,
        //   value: 'income_incr_rate',
        //   name: '收入增长率',
        //   width: 100,
        //   component: 'el-input'
        // },
        // {
        //   id: 4,
        //   value: 'ad_income',
        //   name: '广告收入',
        //   width: 100,
        //   children: []
        // },
        // {
        //   id: 4,
        //   value: 'ad_income',
        //   name: '广告收入',
        //   width: 100,
        //   children: []
        // },
        {
          id: 5,
          value: 'live_commission',
          name: '直播佣金',
          width: 100,
          children: []
        },
        {
          id: 6,
          value: 'short_video',
          name: '短视频收入',
          width: 100,
          children: []
        },
        {
          id: 7,
          value: 'live_position',
          name: '直播坑位费',
          width: 100,
          children: []
        },
        {
          id: 23,
          value: 'goods_profit_total',
          name: '商品销售利润',
          width: 100,
          children: []
        },
        {
          id: 8,
          value: 'cost_total',
          name: '费用合计',
          width: 100
        },
        // {
        //   id: 20,
        //   value: 'kol_divide_rate',
        //   name: '网红分成比例',
        //   width: 100
        // },
        {
          id: 9,
          value: 'kol_divide',
          name: '网红分成',
          width: 100,
          children: []
        },
        {
          id: 10,
          value: 'ad_cost',
          name: 'MCN推广',
          width: 100,
          children: []
        },
        {
          id: 11,
          value: 'live_put',
          name: '直播投放金',
          width: 100,
          children: []
        },
        {
          id: 12,
          value: 'expenses_total',
          name: '报销',
          width: 100
        },
        {
          id: 21,
          value: 'labor_cost',
          name: '人工成本',
          width: 100
        },
        {
          id: 20,
          value: 'biz_supp_team_total',
          name: '商务支持团队',
          width: 100
        },
        //商务支持团队
        {
          id: 22,
          value: 'rebate_total',
          name: '返点费用',
          width: 100
        },
        //办公租金装修费
        //服务管理费
        //税前利润
        //企业所得税
        //净利润
        {
          id: 13,
          value: 'office_rr_total',
          name: '办公租金装修费',
          width: 100
        },
        {
          id: 14,
          value: 'manage_service_fee',
          name: '管理服务费',
          width: 100
        },
        {
          id: 18,
          value: 'fixed_total',
          name: '预估固定费用',
          width: 100
        },
        {
          id: 15,
          value: 'bef_tax_profit',
          name: '税前利润',
          width: 100
        },
        {
          id: 16,
          value: 'corp_income_tax',
          name: '企业所得税',
          width: 100
        },
        {
          id: 17,
          value: 'net_profits',
          name: '净利润',
          width: 100
        },

        // {
        //   id: 14,
        //   value: 'fans_total',
        //   name: '总粉丝数',
        //   width: 100,
        //   children: []
        // },
        // {
        //   id: 15,
        //   value: 'incr_fans_total',
        //   name: '涨粉数',
        //   width: 100,
        //   children: []
        // },
        // {
        //   id: 16,
        //   value: 'fans_incr_rate',
        //   name: '粉丝增长率',
        //   width: 100
        //
        // },
        // {
        //   id: 17,
        //   value: 'fans_price',
        //   name: '粉丝单价',
        //   width: 100
        //
        // },
        // {
        //   id: 18,
        //   value: 'single_fan_income',
        //   name: '单粉收入',
        //   width: 100
        //
        // },
        // {
        //   id: 19,
        //   value: 'profit',
        //   name: '预估利润',
        //   width: 100
        //
        // }
      ],
      tableCol: [],
      currentData: {},
      addColTitle: '',
      dialgText: '',
      newDataList: [],
      artistId: '',
      mapChildData: [
        'live_commission',
        'short_video',
        'goods_profit_total',
        'kol_divide',
        'ad_cost',
        'live_put',
        'fans_total',
        'incr_fans_total',
        'live_position',
        'biz_total',
        'stage_total'
      ],
      projectDate: { year: '', month: '' },
      dialogStore: false,
      dialogValue: '',
      headData: {},
      tipsList: [
        { title: '收入合计', text: '保量收入+商务营收+直播佣金收入+短视频收入+商品销售利润+直播坑位费' },
        { title: '保量收入', text: '数据源：数据管理—营收数据-营收数据录入，“保量收入”' },
        { title: '商务营收', text: '数据源：数据管理—营收数据-营收数据录入，“商务营收”（按平台）' },
        // { title: '直播佣金', text: '来源：数据管理—营收数据/营收数据—"直播佣金"' },
        { title: '直播佣金收入', text: '来源：数据管理—营收数据/营收数据—"直播佣金"' },
        { title: '短视频收入', text: '来源：数据管理—营收数据/营收数据—“短视频佣金”' },
        { title: '直播坑位费', text: '来源：数据管理—营收数据/营收数据—“直播坑位费”' },
        {
          title: '费用合计',
          text: '网红分成+MCN推广+直播投放金+报销+人工成本+商务支持团队+返点费用+预计固定费用+办公租金装修+管理服务费'
        },
        { title: '网红分成', text: '财务导入' },
        { title: 'MCN推广', text: '数据管理-MCN推广' },
        { title: '直播投放金', text: '数据管理-直播投放金' },
        { title: '报销', text: 'OA审批-红人费用报销申请' },
        { title: '人工成本', text: '数据管理-人工成本' },
        { title: '商务支持团队', text: '数据管理-商务支持团队' },
        { title: '返点费用', text: '数据管理-返点费用' },
        { title: '预估固定费用', text: '批量设置' },
        { title: '办公租金装修', text: '财务导入' },
        { title: '管理服务费', text: '财务导入' },
        { title: '税前利润', text: '收入合计 - 费用合计' },
        { title: '企业所得税', text: '税前利润 * 0.27（小于0时，取0）' },
        { title: '净利润', text: '税前利润 - 企业所得税' },
        { title: '商品销售利润', text: '数据源：数据管理—营收数据-营收数据录入，“商品销售利润”' }
      ],
      tipsConfig: {
        title: '说明',
        divide: '：',
        color: '#ff3176',
        size: 'sm'
      },
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
        type: ''
      },
      editData: {
        row: '',
        col: ''
      },
      countCol: [
        {
          name: 'income_total',
          title: '收入合计',
          child: ['live_commission', 'short_video', 'live_position', 'goods_profit_total']
        },
        {
          name: 'cost_total', title: '费用合计',
          child: ['kol_divide', 'ad_cost', 'live_put', 'expenses_total', 'fixed_total', 'labor_cost', 'rebate_total']
        }
      ],
      subData: {},
      formatPoint: false,
      tableEdit: true,
      visible: false,
      divideVisible: false,
      batchfixed: '',
      kolDivide: '',
      divideChild: false,
      logIds: [],
      dialogSync: false,
      sysDate: {}
    }
  },
  props: {
    formData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    formData: {
      immediate: true,
      handler() {
        this.headData = this.formData
      }
    }
  },
  updated() {
    if (this.$refs.myTable) {
      setTimeout(() => {
        this.$refs.myTable.doLayout()
      })
    }
  },
  mounted() {
    this.artistId = this.$route.params.id
    this.projectDate = { year: this.$route.query.year, month: this.$route.query.month }
    this.formatPoint = !!(localStorage.getItem('formatPoint') && localStorage.getItem('formatPoint') != 'false')
    this.tableEdit = !!(localStorage.getItem('tableEdit') && localStorage.getItem('tableEdit') != 'false')
    this.getList()
  },
  computed: {
    ...mapGetters(['userPermissions']),
    signTime() {
      //startMonth 签约时间
      //endMonth 当前年份1月
      let startMonth = String(this.headData.sign_at)
      let endMonth = String(this.projectDate.month)
      let startY = startMonth.split('-')[0],
          startM = startMonth.split('-')[1],
          endY = this.projectDate.year,
          endM = '01'
      startMonth = startY + startM
      endMonth = endY + endM
      if (startY === endY) {
        return endM - startM
      } else {
        if (startMonth > endMonth) {
          let reduY = startY - endY,
              reduM = startM - endM
          return reduY * 12 + reduM
        } else if (startMonth < endMonth) {
          let reduY = endY - startY,
              reduM = endM - startM
          return reduY * 12 + reduM
        } else return 0
      }
    }

  },
  methods: {
    refreshBtn(col) {
      this.$confirm(
          `确定同步【${col.month}月份】的数据吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        this.isLoading = true

        let RefreshData = {
          artist_id: this.artistId,
          date: `${this.projectDate.year}-${String(col.month < 10 ? '0' + col.month : col.month)}-01`
        }
        let data = await this.$api.modelRefresh(RefreshData)
        if (data) {
          this.$message({
            message: '同步成功', type: 'success'
          })
          this.isLoading = false
          location.reload();

        }
      })
    },
    async refresh(col) {
      // await this.refresh(this.newDataList[month - 1])
      this.sysDate = col
      this.dialogSync = true
    },
    cancelBtn() {
      if (this.$refs.sync.canGet) {
        this.$emit('callbackData', true)
      }
      this.dialogSync = false
    },
    columnbackgroundStyle({ columnIndex }) {
      if (Number(this.projectDate.month) === columnIndex) {
        return 'background:#C0C4CC!important;color:white;'
      }
    },

    async calcDivide() {
      let { col } = this.editData
      if (this.formatPoint) {
        if (col.analysis_id) {
          let _income_total = Number(col['income_total'])
          let _kol_divide = _income_total * (col['kol_divide_rate'] * 0.01)
          //新费用合计 labor_cost rebate
          let _newCost = Number(_kol_divide) + Number(col['rebate_total']) + Number(col['labor_cost']) + Number(col['ad_cost'].total) + Number(col['live_put'].total) + Number(col['expenses_total']) + Number(col['fixed_total'])
          //新利润
          let _newProfit = Number(col['income_total']) - Number(_newCost)
          let totalData = {
            id: col['kol_divide'].item_id,
            content: {
              items: col['kol_divide'].items,
              total: _kol_divide
            }
          }
          let msg, data = null
          let profitData = {
            id: col.id,
            cost_total: _newCost,
            profit: _newProfit,
            kol_divide_rate: col['kol_divide_rate']
          }
          data = await this.$api.modelRevise(totalData)
          msg = await this.$api.modelReviseTotal(profitData)
          if (data && msg) {
            col['kol_divide'].total = _kol_divide
            col['cost_total'] = _newCost
            col['profit'] = _newProfit
          } else {
            this.$message('失败,请刷新页面重试')
          }
          this.isLoading = false

        } else {
          await this.exChildTotal()
        }
      } else {
        await this.exChildTotal()
      }
      this.visible = false

      this.isLoading = false
    },
    async handleFixed(item) {
      item['fixed_total'] = this.batchfixed
      if (this.formatPoint) {
        //-费用合计 and 预估利润
        if (item.analysis_id) {
          let _newCost = Number(item['kol_divide'].total) + Number(item['ad_cost'].total) + Number(item['rebate_total']) + Number(item['labor_cost']) + Number(item['live_put'].total) + Number(item['expenses_total']) + Number(this.batchfixed)

          let _newProfit = Number(item['income_total']) - Number(_newCost)
          let reviseData = {
            fixed_total: this.batchfixed,
            id: item.analysis_id,
            cost_total: _newCost,
            profit: _newProfit
          }
          let data = this.$api.modelReviseTotal(reviseData)
          if (data) {
            item['cost_total'] = _newCost
            item['profit'] = _newProfit
          }
        } else {
          //新增
          let date = `${this.projectDate.year}-${String(item.month.month < 10 ? '0' + item.month : item.month)}-01`
          let reviseData = {
            artist_id: this.artistId,
            dept_id: this.headData.group_id,
            fixed_total: this.batchfixed,
            dept_name: this.headData.group_name,
            nickname: this.headData.nickname,
            month: date,
            signed_at: this.headData.sign_start.slice(0, 10),
            coop_duration: item.coop_duration,
            cost_total: this.batchfixed,
            profit: ~this.batchfixed
          }
          await this.$api.modelCreateData(reviseData)
        }
      } else {
        if (item.analysis_id) {
          //修改
          let reviseData = {}
          reviseData = {
            fixed_total: this.batchfixed,
            id: item.analysis_id
          }
          await this.$api.modelReviseTotal(reviseData)

        } else {
          //新增
          let date = `${this.projectDate.year}-${String(item.month.month < 10 ? '0' + item.month : item.month)}-01`
          let reviseData = {
            artist_id: this.artistId,
            dept_id: this.headData.group_id,
            fixed_total: this.batchfixed,
            dept_name: this.headData.group_name,
            nickname: this.headData.nickname,
            month: date,
            signed_at: this.headData.sign_start.slice(0, 10),
            coop_duration: item.coop_duration,
            fans_incr_rate: null
          }
          await this.$api.modelCreateData(reviseData)
        }
      }

    },

    async handleYearDate(type) {

      type === 'fixed' ? this.visible = true : this.divideVisible = true
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1

      if (this.projectDate.year === String(year)) {
        //只创建几个月
        if (type === 'fixed') {
          for (let i = 0; i < month; i++) {
            await this.handleFixed(this.newDataList[i])
          }
        } else if (type === 'divide') {
          for (let i = 0; i < month; i++) {
            await this.batchKolDivide(this.newDataList[i])
            // this.handleFixed(this.newDataList[i])
          }
        }

      } else {
        if (type === 'fixed') {
          this.newDataList.forEach((item) => {
            this.handleFixed(item)
          })
        } else if (type === 'divide') {
          this.newDataList.forEach((item) => {
            this.batchKolDivide(item)
          })
        }
      }

    },
    async batchKolDivide(item) {
      item['kol_divide_rate'] = this.kolDivide
      if (this.formatPoint) {
        if (item.analysis_id) {
          let _income_total = Number(item['income_total'])
          let _kol_divide = _income_total * (item['kol_divide_rate'] * 0.01)
          //新费用合计
          let _newCost = Number(_kol_divide) + Number(item['ad_cost'].total) + Number(item['rebate_total']) + Number(item['labor_cost']) + Number(item['live_put'].total) + Number(item['expenses_total']) + Number(item['fixed_total'])
          //新利润
          let _newProfit = Number(item['income_total']) - Number(_newCost)
          let totalData = {
            id: item['kol_divide'].item_id,
            content: {
              items: item['kol_divide'].items,
              total: _kol_divide
            }
          }
          let msg, data = null
          let profitData = {
            id: item.id,
            cost_total: _newCost,
            profit: _newProfit,
            kol_divide_rate: item['kol_divide_rate']
          }
          data = await this.$api.modelRevise(totalData)
          msg = await this.$api.modelReviseTotal(profitData)
          if (data && msg) {
            item['kol_divide'].total = _kol_divide
            item['cost_total'] = _newCost
            item['profit'] = _newProfit
          } else {
            this.$message('失败,请刷新页面重试')
          }
          this.isLoading = false
        } else {
          //新增
          let date = `${this.projectDate.year}-${String(item.month.month < 10 ? '0' + item.month : item.month)}-01`
          let reviseData = {
            artist_id: this.artistId,
            dept_id: this.headData.group_id,
            kol_divide_rate: this.kolDivide,
            dept_name: this.headData.group_name,
            nickname: this.headData.nickname,
            month: date,
            signed_at: this.headData.sign_start.slice(0, 10),
            coop_duration: item.coop_duration,
            fans_incr_rate: null
          }
          await this.$api.modelCreateData(reviseData)
        }
      } else {
        let profitData = {
          id: item.id,
          kol_divide_rate: item['kol_divide_rate']
        }
        let msg = await this.$api.modelReviseTotal(profitData)
        if (!msg) {
          this.$message('失败,请联系管理员')
          this.$emit('callbackData', true)
          3
        }
        this.isLoading = false
      }
    },
    isEdit(row) {
      return this.tableEdit && row.value !== 'coop_duration' && this.userPermissions.indexOf('kol-analysis-item-update') != -1
    },
    changeLocalStorage(type) {
      type === 'formatPoint' ? localStorage.setItem('formatPoint', this.formatPoint) : localStorage.setItem('tableEdit', this.tableEdit)
    },
    hoverTitle(row) {
      let title = this.tipsList.map(_ => {
        return _.title === row.name ? _.text : undefined
      }).filter(Boolean)
      return title[0]
    },

    //合计行
    calcRow(row) {
      let colValue = row.value
      let cont = 0
      if (this.mapChildData.includes(row.value) && !row.children) {
        cont = this.calc(row.value, row.name)
      } else {
        this.newDataList.forEach((item) => {
          let dataItems = item[colValue]?.items
          if (!row.children) {
            if (dataItems && dataItems.length > 0) {
              dataItems.forEach((i) => {
                if (row.name === i.title) {
                  cont = cont + Number(i.val) + '//' + i.title + '//' + i.value
                }
              })
            } else {
              cont = cont + Number(item[colValue])
            }
          } else if (row.children && item[colValue].total) {
            cont = cont + Number(item[colValue].total)
          }
        })
      }
      return cont
    },
    //新增行
    async addNewField() {
      let row = this.dialogValue
      let data = this.dataList[0][row.value]
      let b = []
      this.tableCol.forEach(i => {
        if (i.value === row.value) {
          b = i.children.map(j => {
            return j.name === this.addColTitle ? j : null
          }).filter(Boolean)
        }
      })
      if (b.length != 0) {
        this.dialgText = '该平台已存在'
        return false
      }
      if (this.addColTitle != '' && this.addColTitle != '请输入') {
        let newRow = {}
        newRow = {
          name: this.addColTitle,
          val: 0
        }
        let newItems = data.items
        newItems.push(newRow)
        let reviseData = {
          id: data.item_id,
          content: {
            items: newItems,
            total: data.total
          }
        }
        let msg = await this.$api.modelRevise(reviseData)
        if (msg) {
          row.children.push({
            id: Math.floor(Math.random() * 999) + 1 + 'addChild',
            name: this.addColTitle,
            val: 0,
            value: row.value,
            isChild: true
          })
          this.newDataList.forEach((item) => {
            item[row.value].items.push({
              id: Math.floor(Math.random() * 999) + 1 + 'addChild',
              name: this.addColTitle,
              val: 0,
              value: row.value
            })
          })
        }

        // this.$emit('callbackData', true)
        // this.getList()
        this.dialogStore = false
      }
    },
    //同步月
    //删除子项
    delField(row) {
      let i = this.dataList.length
      this.$confirm(
          `确认删除${row.name}平台数据吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        this.dataList.forEach((item) => {
          let mapData = item[row.value].items.map((data) => {
            if (data.name != row.name) {
              return data
            }
          }).filter(item2 => item2 !== undefined)
          let reviseData = {
            id: item[row.value].item_id,
            content: {
              items: mapData,
              total: item[row.value].total
            }
          }

          let data = this.$api.modelDelChildData(reviseData)
          if (data) {
            i--
            if (i === 0) {
              this.$message({
                message: '删除成功', type: 'success'
              })
              let reduce = this.tableCol.map(i => {
                return i.value === row.value ? i : null
              }).filter(Boolean)[0]
              reduce.children.forEach(((data, i) => {
                if (data.name === row.name) {
                  reduce.children.splice(i, 1)
                }
              }))
            }
          }
        })
      })
    },
    ///构建表格初始化
    getList() {
      //初始化
      this.isLoading = true
      this.newDataList = []
      this.tableCol = []
      this.addColTitle = ''
      this.getDataList()
    },
    async getDataList() {
      let date = `${this.projectDate.year}-${this.projectDate.month}-01`
      this.dataList = await this.$api.modelGetModelDetail({ artist_id: this.artistId, date: date })
      this.getNewCol()

    },
    getNewCol: function() {
      //创建12列数据
      let j = 0
      let month = this.dataList.map((item) => {
        return item.month - 1
      })
      for (let i = 0; i < 12; i++) {
        if (!month.includes(i)) {
          let newData = {
            coop_duration: this.signTime != '-' ? this.signTime + i + 1 : this.signTime,
            income_total: 0.00,
            // income_incr_rate: 0,
            ad_income: {
              items: [],
              total: 0
            },
            stage_total: {
              items: [],
              total: 0
            },
            biz_total: {
              items: [],
              total: 0
            },
            live_commission: {
              items: [],
              total: 0
            },
            short_video: {
              items: [],
              total: 0
            },
            live_position: {
              items: [],
              total: 0
            },
            goods_profit_total:{
              items: [],
              total: 0
            },
            cost_total: 0,
            kol_divide: {
              items: [],
              total: 0
            },
            ad_cost: {
              items: [],
              total: 0
            },
            live_put: {
              items: [],
              total: 0
            },
            expenses_total: 0,
            fixed_total: 0,
            fans_total: {
              items: [],
              total: 0
            },
            incr_fans_total: {
              items: [],
              total: 0
            },
            fans_incr_rate: 0,
            fans_price: 0,
            single_fan_income: 0,
            profit: 0,
            month: i + 1,
            kol_divide_rate: 0,
            labor_cost: 0,
            rebate_total: 0
          }
          this.$set(this.newDataList, i, newData)
        } else {
          this.dataList[j].coop_duration = this.signTime !== '-' ? this.signTime + i + 1 : this.signTime
          this.logIds.push(this.dataList[j].analysis_id)
          this.$set(this.newDataList, i, JSON.parse(JSON.stringify(this.dataList[j])))
          j++
        }
      }
      this.defaultTabData()
    },
    defaultTabData: function() {
      this.tableCol = this.colData
      let newItem = []
      let mapNewData = []

      this.mapChildData.forEach((name) => {
        this.dataList.forEach(i => {
          if (i[name].items) {
            mapNewData.push({ data: i[name], name: name })
          }
        })
      })


      mapNewData.forEach(item => {
        if (item.data) {
          item.data.items.forEach(v => {
            let incloud = newItem.find((i) => {
              return i.name === v.name && i.value === item.name
            })
            if (!incloud) {
              let newV = {
                name: v.name,
                val: v.val && v.val != '' ? v.val : 0,
                value: item.name,
                id: Math.floor(Math.random() * 9999) + 'childId',
                isChild: true
              }
              newItem.push(newV)
            }
          })
        }
      })

      //push 子项
      this.tableCol.forEach(i => {
        if (i.children) {
          newItem.forEach((item) => {
            if (i.value === item.value) {
              i.children.push(item)
            }
          })
        }
      })
      //构造table
      this.newDataList.forEach((item) => {
        this.tableCol.forEach((v) => {
          let colName = v.value
          let itemValue = item[colName]
          let itemsValue = itemValue?.items
          if (Array.isArray(itemsValue)) {
            if (itemsValue.length === 0 && v.children?.length != 0) {
              //有子项 月份没有数据的push进去
              v.children.forEach((i) => {
                let newV = {
                  id: Math.floor(Math.random() * 999) + 1 + 'newId',
                  value: i.value,
                  name: i.name,
                  val: v.val && v.val != '' ? v.val : 0
                }
                itemsValue.push(newV)
              })
            } else if (itemsValue.length != 0) {
              //有子项 月份有数据但是不全的push进去
              if (v.children.length > itemsValue.length) {
                let childName = v.children.map(({ name }) => {
                  return name
                })
                let itemsName = itemsValue.map(({ name }) => {
                  return name
                })
                const setArray = childName.filter(x => !itemsName.includes(x))

                setArray.forEach((data) => {
                  let newV = {
                    id: Math.floor(Math.random() * 999) + 1 + 'newId',
                    value: colName,
                    name: data,
                    val: 0
                  }
                  itemsValue.push(newV)
                })
              }
            }
          }

        })
      })
      setTimeout(() => {
        this.isLoading = false
      }, 800)

    },

    ///其他数据计算
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    calc(field, platform_name) {
      if (!field || !platform_name) {
        return
      }
      let total = 0

      this.newDataList.map(lever_1 => {
        const array = lever_1[field].items
        if (Array.isArray(array)) {
          const platform = array.find(function(_) {
            return _.name === platform_name
          })
          if (platform) {
            total += (Number(platform.val) || 0)
          }
        }
      })
      return total
    },

    reverse(num) {
      let returnData
      if (num === 0) {
        returnData = 0
      } else if (num > 0) {
        returnData = -num
      } else if (num < 0) {
        returnData = Math.abs(num)
      }
      return returnData
    },
    async addField() {
      let { row, col } = this.editData
      let value = row.value
      let date = `${this.projectDate.year}-${String(col.month < 10 ? '0' + col.month : col.month)}-01`
      let newType = {
        live_commission: 4,
        short_video: 17,
        kol_divide: 6,
        ad_cost: 7,
        fans_total: 12,
        incr_fans_total: 13,
        live_position: 18,
        live_put: 8
      }
      if (!newType[value]) {
        //没有子项
        let reviseData = {
          artist_id: this.artistId,
          dept_id: this.headData.group_id,
          [value]: col[value],
          dept_name: this.headData.group_name,
          nickname: this.headData.nickname,
          month: date,
          signed_at: this.headData.sign_start.slice(0, 10),
          coop_duration: col.coop_duration
        }
        if (this.formatPoint) {
          let itemData = this.includeRange()
          if (row.value === 'income_total' || row.value === 'cost_total') {
            row.value === 'cost_total' ? reviseData['profit'] = this.reverse(Number(col[value])) : reviseData['profit'] = col[value]

          } else if (itemData) {
            let _name = itemData.name
            reviseData[itemData.name] = col[value]
            _name === 'cost_total' ? reviseData['profit'] = this.reverse(Number(col[value])) : reviseData['profit'] = col[value]
          }
          let data = await this.$api.modelCreateData(reviseData)
          if (data) {
            if (row.value === 'income_total' || row.value === 'cost_total') {
              row.value === 'cost_total' ? this.editData.col['profit'] = this.reverse(Number(col[value])) : this.editData.col['profit'] = col[value]
            } else if (itemData) {
              let _name = itemData.name
              _name === 'income_total' ? this.editData.col['income_total'] = col[value] : _name === 'cost_total' ? this.editData.col['cost_total'] = col[value] : ''
              _name === 'income_total' ? this.editData.col['profit'] = col[value] : _name === 'cost_total' ? this.editData.col['profit'] = this.reverse(Number(col[value])) : ''
            }

            this.isLoading = false
          }
        } else {
          let data = await this.$api.modelCreateData(reviseData)
          if (data) {
            this.isLoading = false
          }
        }
      } else {
        //有子项
        let reviseData = {
          artist_id: this.artistId,
          dept_id: this.headData.group_id,
          dept_name: this.headData.group_name,
          nickname: this.headData.nickname,
          month: date,
          signed_at: this.headData.sign_start.slice(0, 10),
          coop_duration: col.coop_duration,
          content: {
            items: col[value]?.items,
            total: col[value]?.total
          },
          type: newType[value]
        }
        if (this.formatPoint) {
          let val = 0
          if (row.isChild) {
            //是子项
            col[value].items.forEach((i) => {
              val += Number(i.val)
            })
            reviseData.content.total = val
            let itemData = this.includeRange()
            if (itemData) {
              //属于新增-费用合计和收入合计种的 -要改利润or费用or利润
              let _name = itemData.name
              _name === 'income_total' ? reviseData ['profit'] = val : ''
              _name === 'cost_total' ? reviseData ['profit'] = Number(-val) : ''
              reviseData[_name] = val
              let data = this.addSubmit(reviseData)
              if (data) {
                this.editData.col[this.editData.row.value].total = val || 0
                _name === 'income_total' ? this.editData.col['income_total'] = val : _name === 'cost_total' ? this.editData.col['cost_total'] = val : ''
                this.editData.col['profit'] = reviseData ['profit']
                this.isLoading = false
              }
            } else {
              //不属于费用合计收入合计啥的
              let data = this.addSubmit(reviseData)
              if (!data) {
                this.$message('失败,请重新刷新页面')
              }
            }
          } else {
            //是总数
            let itemData = this.includeRange()
            if (itemData) {
              let _name = itemData.name
              _name === 'income_total' ? reviseData['profit'] = col[value].total : ''
              _name === 'cost_total' ? reviseData['profit'] = Number(-col[value].total) : ''
              reviseData[_name] = col[value].total
              let data = this.addSubmit(reviseData)
              if (data) {
                _name === 'income_total' ? this.editData.col['income_total'] = col[value].total : _name === 'cost_total' ? this.editData.col['cost_total'] = col[value].total : ''
                this.editData.col['profit'] = reviseData['profit']
                this.isLoading = false
              }
            } else {
              let data = this.addSubmit(reviseData)
              if (!data) {
                this.$message('失败,请重新刷新页面')
              }
              this.isLoading = false
            }
          }
          this.isLoading = false
        } else {
          await this.addSubmit(reviseData)
        }

        // let data = await this.$api.modelCreateData(reviseData)
        // if (data) {
        //   this.isLoading = false
        // }
      }
      this.isLoading = false

    },
    async addSubmit(reviseData) {
      let data = await this.$api.modelCreateData(reviseData)
      return data
    },


    ///表格数据修改
    changeVal(col, row) {
      this.editData = {}
      this.editData = {
        row: row,
        col: col
      }
      if (col.analysis_id) {
        this.isLoading = true
        //修改逻辑
        this.editField()
      } else {
        //新增逻辑
        this.isLoading = true
        this.addField()
      }
    },
    editField() {
      let { row, col } = this.editData
      let value = row.value
      let colValue = col[value]
      if (!row.children && !colValue.item_id) {
        //修改-无子项
        switch (value) {
          case 'income_total':
            this.verifyData(value)
            break
          case 'cost_total':
            this.verifyData(value)
            break
          case 'expenses_total':
            this.fixedTotal()
            break
          case 'fixed_total':
            this.fixedTotal()
            break
          case 'labor_cost':
            this.fixedTotal()
            break
          case 'rebate_total':
            this.fixedTotal()
            break
          case 'kol_divide_rate':
            this.calcDivide()
            break
            // case 'single_fan_income':
            //   this.singleFanCalc()
            //   break
          default:
            this.exChildTotal()
            break
        }
      } else {
        // //修改-有子项
        this.hasChildTotal()
      }
    },
    hasChildTotal() {
      //是否是总和
      let { row } = this.editData
      row.isChild ? this.calcChild() : this.calcTotal()
    },


    //单粉收入计算
    //     async singleFanCalc() {
    //       let { col, row } = this.editData
    //       //单粉收入修改
    //       let value = row.value
    //       let colValue = col[value]
    //       //本月收入/本月粉丝数
    //       let income_total = col['income_total']
    //       let incr_fans_total = col['incr_fans_total'].total
    //       //应该的值
    //       let calcValue = (income_total / income_total) == 'Infinity' ? '-' : income_total / income_total
    //       if (colValue != calcValue) {
    //         this.dialogSync = true
    // //         this.collectHtml = `<span style="color: #f24b88">${row.name}</span>计算的值应该是【￥${this.moneyFormat(calcValue)}】,和现在修改的【￥${this.moneyFormat(colValue)}】不相符。
    // // <br><a style="cursor: pointer;text-decoration:underline;color: #409EFF" onclick="exChildTotal()">确定修改</a><br>`
    //         this.collectTable = [
    //           { name: '本月收入', value: income_total },
    //           { name: '本月涨粉数', value: incr_fans_total },
    //           { name: '单粉收入', value: calcValue, edit: colValue }
    //         ]
    //         // this.collectTable.push({ name: row.name, calc: calcValue, edit: colValue })
    //
    //         //
    //         // // this.collectHtml = {
    //         // //   [row.name]: calcValue
    //         // // }
    //       } else this.exChildTotal()
    //     },


    verifyData() {
      if (this.formatPoint) {
        this.exChildTotal('profit')
      } else this.exChildTotal()
    },
    async exChildTotal(type = 'only') {
      let { col, row } = this.editData
      //无子项的修改
      let value = row.value
      let colValue = col[value]
      if (type === 'only') {
        let reviseData = {}
        reviseData = {
          [value]: colValue,
          id: col.analysis_id
        }
        let data = await this.$api.modelReviseTotal(reviseData)
        if (data) {
          col[value] = colValue
        }
        this.isLoading = false
      } else {
        let { newProfit } = this.countProfit()
        let reviseData = {
          id: col.analysis_id,
          [value]: colValue,
          profit: newProfit
        }
        if (row.value === 'income_total') {
          let { _NewkolDivide } = this.countProfit()
          let data = await this.$api.modelRevise(_NewkolDivide)
          if (data) {
            col['kol_divide'].total = _NewkolDivide.content.total
          }
          this.calcFansPrice('single')
        }
        let msg = await this.$api.modelReviseTotal(reviseData)
        if (msg) {
          col['profit'] = newProfit
          col[value] = colValue
        } else {
          this.$message('失败,请刷新页面重试')
        }
        this.isLoading = false

      }
    },
    //计算total的值


    countProfit() {
      //传进来的是新的income_totalor或者cost_total
      let { col, row } = this.editData
      let value = row.value
      let colValue = col[value]
      let cost_total = col['cost_total']
      let income_total = col['income_total']
      let kol_divide_rate = Number(col['kol_divide_rate']) / 100
      let _NewkolDivide = {}
      let newProfit = 0
      if (value === 'income_total') {
        newProfit = colValue - cost_total
        // id: col['kol_divide']
        _NewkolDivide = {
          id: col['kol_divide'].item_id,
          content: { items: col['kol_divide'].items, total: colValue * kol_divide_rate }
        }
      } else {
        newProfit = income_total - colValue
      }
      return { newProfit, _NewkolDivide }
    },

    includeRange() {
      //是否属于收入合计or费用合计中
      let { row } = this.editData
      let income_total, cost_total, itemData
      income_total = this.countCol[0].child.map(i => {
        return i.includes(row.value) ? this.countCol[0] : undefined
      }).filter(Boolean)
      cost_total = this.countCol[1].child.map(i => {
        return i.includes(row.value) ? this.countCol[1] : undefined
      }).filter(Boolean)
      itemData = income_total.length === 1 ? income_total[0] : cost_total.length === 1 ? cost_total[0] : undefined

      return itemData
    },
    totalize(data) {
      let { row, col } = this.editData
      if (data) {
        //收入合计
        if (data.name === 'income_total') {
          let newIncome = 0
          data.child.forEach((name) => {
            if (name != row.value) {
              newIncome = Number(newIncome) + (Number(col[name].total) || 0)
            }
          })
          return { itemData: data, newIncome: newIncome }
        } else {
          //支出合计
          let newIncome = 0
          data.child.forEach((name) => {
            if (name != row.value) {
              if (col[name]?.total || col[name]?.total == 0) {
                newIncome = Number(newIncome) + (Number(col[name].total) || 0)
              } else {
                newIncome = Number(newIncome) + (Number(col[name]) || 0)
              }
            }
          })
          return { newIncome: newIncome }
        }
        // return { itemData: itemData, newIncome: newIncome }
      } else
        return false

    },
    childTotal() {
      let { row, col } = this.editData
      console.log(row.value,'value')
      let colData = col[row.value]
      let newItems = []
      colData?.items.forEach((i) => {
        newItems.push({ val: i.val || 0, name: i.name })
      })
      let reviseData = {
        id: colData.item_id,
        content: {
          items: newItems,
          total: colData.total
        }
      }
      let items = reviseData.content.items
      const childCalc = items.reduce((prev, cur) => {
        return Number(prev) + Number(cur.val)
      }, 0)
      return { childCalc: childCalc, reviseData: reviseData }
    },

    contNewProfit(newIncome, itemData, child) {
      let { reviseData } = this.childTotal()
      let { col } = this.editData
      let cost_total = col['cost_total']
      let income_total = col['income_total']
      let newObject = {
        total: child ? child : reviseData.content.total,
        col_id: col.id,
        [itemData.name]: newIncome
      }
      if (itemData.name === 'income_total') {
        let kol_divide_rate = Number(col['kol_divide_rate']) / 100
        //新红人分成
        let kol_divide = newIncome * kol_divide_rate
        //新花费
        cost_total = Number(kol_divide) + Number(col['labor_cost']) + Number(col['ad_cost'].total) + Number(col['live_put'].total) + Number(col['expenses_total']) + Number(col['fixed_total'])
        newObject.cost_total = cost_total
        //新红人分成
        let _NewkolDivide = {
          id: col['kol_divide'].item_id,
          content: { items: col['kol_divide'].items, total: kol_divide }
        }
        newObject.kol_divide = _NewkolDivide
      }
      let newProfit = itemData.name === 'income_total' ? newIncome - cost_total : income_total - newIncome
      newObject.profit = newProfit
      this.subData = Object.assign(reviseData, newObject)
    },
    async calcFansPrice(type) {

      let { col } = this.editData
      if (type === 'fansPrice') {
        let ad_cost = col['ad_cost'].total
        let incr_fans_total = col['incr_fans_total'].total
        let fansPrice = 0
        if (ad_cost || incr_fans_total) {
          fansPrice = ad_cost / incr_fans_total
        }
        let profitData = {
          id: col.analysis_id,
          fans_price: fansPrice
        }
        let msg = await this.$api.modelReviseTotal(profitData)
        if (msg) {
          col['fans_price'] = fansPrice
        }
      } else {
        let income_total = col['income_total']
        let fans_total = col['fans_total'].total
        let single = 0
        if (income_total || fans_total) {
          single = income_total / fans_total
        }
        let profitData = {
          id: col.analysis_id,
          single_fan_income: single
        }
        let msg = await this.$api.modelReviseTotal(profitData)
        if (msg) {
          col['single_fan_income'] = single
        }
      }

      this.isLoading = false


    },
    calcTotal() {
      //这里是计算total合计
      let { row } = this.editData

      let { reviseData } = this.childTotal()

      // childTotal
      if (this.formatPoint) {
        let _d = this.includeRange()
        if (_d) {
          let itemData = _d
          let { newIncome } = this.totalize(itemData)
          newIncome += Number(reviseData.content.total)
          this.contNewProfit(newIncome, itemData)
          this.editTotal('profit')
        } else {
          this.subData = reviseData
          this.editTotal('only')
        }
        if (row.value === 'ad_cost' || row.value === 'incr_fans_total') {
          this.calcFansPrice('fansPrice')
        }
        if (row.value === 'fans_total') {
          this.calcFansPrice('single')
        }
      } else {
        this.subData = reviseData
        this.editTotal('only')
      }
    }
    ,
//计算合计下子值的
    calcChild() {
      let { row } = this.editData
      let { childCalc, reviseData } = this.childTotal()
      if (this.formatPoint && row.value != 'kol_divide') {
        let _d = this.includeRange()
        if (_d) {
          let itemData = _d
          let { newIncome } = this.totalize(itemData)
          newIncome += Number(childCalc)
          this.contNewProfit(newIncome, itemData, childCalc)
          // this.subData = Object.assign(reviseData, newObject)
          this.editTotal('profit')
        } else {
          reviseData.content.total = childCalc
          this.subData = reviseData
          this.editTotal('fans')
        }
      } else {

        this.subData = reviseData
        this.editTotal('only')
      }
    },
    async fixedTotal() {
      //修改合计 提醒
      if (this.formatPoint) {
        let { col, row } = this.editData
        let _d = this.includeRange()
        let { newIncome } = this.totalize(_d)
        let income_total = col['income_total']
        newIncome += Number(col[row.value])
        let profit = Number(income_total) - Number(newIncome)
        //费用合计 -需要改一个费用合计 一个利润 一个是自身值
        let data = {
          cost_total: newIncome,
          profit: profit,
          [row.value]: col[row.value],
          id: col['analysis_id']
        }
        let msg = await this.$api.modelReviseTotal(data)
        if (msg) {
          this.editData.col['cost_total'] = newIncome
          this.editData.col['profit'] = profit
          this.editData.col[row.value] = col[row.value]
        } else {
          this.$message('失败,请刷新页面重试')
        }
      } else await this.exChildTotal()
      this.isLoading = false
    },
    async editTotal(type) {
      let { col_id, income_total, profit, cost_total } = this.subData
      let isIncome = income_total && income_total != undefined
      if (type === 'only') {
        let onlyData = {
          id: this.subData.id,
          content: this.subData.content
        }
        let msg = await this.$api.modelRevise(onlyData)
        if (!msg) {
          this.$message('失败,请刷新页面重试')
        }
        this.isLoading = false
      } else if (type === 'profit') {
        let totalData = {
          id: this.subData.id,
          content: this.subData.content
        }
        totalData.content.total = this.subData.total || 0
        let msg, data = null
        let profitData = {}

        profitData = isIncome ?
            {
              id: col_id,
              income_total: income_total,
              profit: profit,
              cost_total: cost_total
            } : {
              id: col_id,
              cost_total: cost_total,
              profit: profit
            }
        data = await this.$api.modelRevise(totalData)
        msg = await this.$api.modelReviseTotal(profitData)
        if (isIncome) {
          let id = await this.$api.modelRevise(this.subData.kol_divide)
          if (id) {
            this.editData.col['kol_divide'].total = this.subData.kol_divide.content.total
          } else {
            this.$message('红人分成修改错误')
          }
        }
        if (data && msg) {
          setTimeout(() => {
            this.editData.col[this.editData.row.value].total = this.subData.total || 0
            if (isIncome) {
              this.editData.col['income_total'] = income_total
              this.editData.col['cost_total'] = cost_total
            } else this.editData.col['cost_total'] = cost_total
            this.editData.col['profit'] = profit
            this.isLoading = false
          }, 800)
        } else {
          this.$message('失败,请刷新页面重试')
          this.isLoading = false
        }
      } else if (type === 'fans') {
        let msg = await this.$api.modelRevise(this.subData)
        if (msg) {
          this.editData.col[this.editData.row.value].total = this.subData.content.total || 0
        } else {
          this.$message('失败,请刷新页面重试')
        }
      } else if (type === 'divide') {
        let totalData = {
          id: this.subData.id,
          content: this.subData.content
        }
        totalData.content.total = this.subData.total || 0
        let msg, data = null
        let profitData = {
          id: col_id,
          cost_total: cost_total,
          profit: profit
        }
        data = await this.$api.modelRevise(totalData)
        msg = await this.$api.modelReviseTotal(profitData)
        if (data && msg) {
          this.isLoading = false
        } else {
          this.$message('失败,请刷新页面重试')
          this.isLoading = false
        }
      }
    },


    // 下载表格
    exportExcel: function() {
      this.downloadLoading = true
      const xlsxParam = { raw: false } //转换成excel时，使用原始的格式
      /* 生成工作表 */
      const workbook = XLSX.utils.book_new()
      /* 从表生成工作簿对象 */
      let worksheet = XLSX.utils.table_to_sheet(
          document.querySelector('#kolAnalysisTable'),
          xlsxParam
      )
      /* 设置工作簿样式 */
      worksheet = this.worksheetStyle(worksheet)
      /* 工作簿导出 */
      XLSX.utils.book_append_sheet(workbook, worksheet, '单个红人数据分析')
      XLSX.writeFile(workbook, `${this.projectDate.year}年${this.headData.nickname}红人平台&财务数据分析.xlsx`)

      this.downloadLoading = false
    },
    /* 设置工作簿样式 */
    worksheetStyle: function(worksheet) {
      // 表样式设置
      const colsLength = 14
      let cols = [] // 设置每列的宽度
      // wpx 字段表示以像素为单位，wch 字段表示以字符为单位
      for (let i = 0; i <= colsLength; i++) {
        let col = {}
        if (i == 0) {
          col.wch = 30
        } else {
          col.wch = 18
        }
        cols.push(col)
      }
      worksheet['!cols'] = cols // 设置列宽信息到工作表
      //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
      Object.keys(worksheet).forEach(key => {

        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
          if (key.indexOf('A') != -1 || ['B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1'].includes(key)) {
            worksheet[key].v = worksheet[key].v.indexOf('预估固定费用') === 0 ? '预估固定费用' : worksheet[key].v
            if (key.indexOf('A') != -1) {
              if (key === 'A1') {
                worksheet[key].s = {
                  font: {
                    sz: '20',
                    name: 'Microsoft YaHei',
                    color: { rgb: 'FFFFFF' }

                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' }
                  },
                  fill: {
                    fgColor: { rgb: '62d2ae' }
                  }
                }
              } else
                worksheet[key].s = {
                  font: {
                    sz: '12',
                    name: 'Microsoft YaHei'
                  },
                  alignment: {
                    horizontal: 'center',
                    vertical: 'center',
                    wrapText: true
                  },
                  border: {
                    top: { style: 'thin' },
                    right: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' }
                  }
                }
            } else {
              worksheet[key].s = {
                font: {
                  sz: '12',
                  color: { rgb: 'FFFFFF' },
                  name: 'Microsoft YaHei'
                },
                alignment: {
                  horizontal: 'center',
                  vertical: 'center',
                  wrapText: true
                },
                border: {
                  top: { style: 'thin' },
                  right: { style: 'thin' },
                  bottom: { style: 'thin' },
                  left: { style: 'thin' }
                },
                fill: {
                  fgColor: { rgb: '62d2ae' }
                }
              }
            }
            worksheet[key].t = 's'
          } else {
            worksheet[key].s = {
              font: {
                sz: '14'
              },
              alignment: {
                horizontal: 'right',
                vertical: 'right',
                wrapText: true
              },
              border: {
                top: { style: 'thin' },
                right: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' }
              }
            }
            worksheet[key].t = 'n'
            worksheet[key].s.numFmt = 2
          }
        }

      })

      return worksheet
    },

    dialogShow(row) {
      this.dialogValue = row
      this.dialogStore = true
    }
    ,
    headerClassName({ column, columnIndex }) {
      column.month = columnIndex
    }
    ,
    cellClassName({ row, rowIndex }) {
      row.index = rowIndex
    }
    ,
    async rowContextmenu(row, column, e) {
      this.currentData = { row, column }
      e.preventDefault()//阻止元素发生默认的行为
      let i = [0, 13]
      if (!i.includes(column.month)) {
        this.openMenu(row, column, e)
      }
    },
    openMenu(row, column, e) {
      this.currentData = { row, column }
      e.preventDefault()//阻止元素发生默认的行为
      const offsetLeft = this.$el.getBoundingClientRect().left
      // const offsetTop = this.$el.getBoundingClientRect().top
      this.menuData = {
        visible: true,
        left: e.clientX - offsetLeft,
        top: e.pageY
      }
      this.menuData.type = row?.isChild ? 'child' : row?.children ? 'total' : ''
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },
    closeMenu() {
      // if (e.target.className.indexOf('contextmenu__item') === -1) {
      this.menuData.visible = false
      document.removeEventListener('click', this.closeMenu)
      // }
    },
    async changeCheck(val) {
      let { month } = this.currentData.column
      if (val === 'refresh') {
        //同步当前月数据
        // await this.refresh(this.newDataList[month - 1])
        this.sysDate = this.newDataList[month - 1]
        this.dialogSync = true
      } else if (val === 'del') {
        this.delField(this.currentData.row)
      } else if (val === 'add') {
        this.dialogShow(this.currentData.row.value)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.default-table {
  //overflow: hidden;

  background-color: #FFFFFF;
  border-radius: 10px;
}

::v-deep .tableWrapper {
  font-size: 20px;
  background-color: #f2f2f2 !important;
  color: #bf1755;
  font-weight: bold;
}

.tableBox {
  width: 99%;
  margin: 0 auto;
  height: auto;
  display: flex;

  .tableTitle {
    width: 100px;
    height: auto;
    left: 0;

    > span {
      display: block;
    }
  }

  .tableContent {
    width: calc(100% - 120px);
    //float: left;
    flex-wrap: wrap;
    //align-items: center;
    justify-content: center;

    .moon {
      font-size: 16px;
      width: calc(100% / 12 - 20px);
      float: left;
      margin: 10px auto;

      //max-height: 280px;
      > h2 {
      }
    }

    > * {
      border: 1px solid #590e2a;
    }
  }
}

.row > * {
  text-align: center;
}

::v-deep .el-table th {
  overflow: initial;
}

::v-deep.el-table th > .cell {
  font-family: PingFangSC-Regular, sans-serif;
  font-size: 14px;
  color: #2e3444;
  text-align: center;

}


::v-deep.el-table thead tr:last-of-type th:first-of-type:before {
  text-align: center;
  position: absolute;
  width: 1px;
  background-color: #ebeef5;
  display: block;

}

::v-deep.el-table thead tr:last-of-type th:first-of-type:before {
  height: 183px; //根据情况调整
  bottom: 0;
  right: 0; //根据情况调整
  transform: rotate(-72deg); //根据情况调整
  transform-origin: bottom;
}

::v-deep.el-table tbody tr td:first-of-type .cell {
  font-family: PingFangSC-Regular, sans-serif;
  font-size: 14px;
  color: #2e3444;
  font-weight: 600;
  text-align: center;

}

::v-deep .el-table--mini .el-table__cell {
  padding: 8px 0;
}

.switch_items {
  float: right;
  display: flex;


  > * {
    display: block;
    margin-left: 20px;
    //border: 1px solid red;
    //text-align: center;
  }
}

</style>
<style>
.el-table .light-green {
  background: #62d2ae !important;
}

.el-table .dark-green {
  font-weight: bold !important;
  font-size: 18px !important;
  background: #5ca58d !important;
}
</style>
